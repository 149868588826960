import React from "react"
import pr from "src/assets/images/pr.jpg"
import niepodlegla from "src/assets/images/kul.jpg"
import pr2 from "src/assets/images/pr2.svg"
import fina from "src/assets/images/fina.svg"
import pilsudski from "src/assets/images/pilsudski.svg"
import bud from "src/assets/images/budHU.jpg"

import niepodlegla2 from "src/assets/images/niepodlegla2.svg"
import { isMobile } from "react-device-detect"
import styled from "styled-components"

const CreditsBody = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f5f4ee;
  padding-left: 8rem;
  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  ${isMobile &&
    `
      padding-left: 0;
    `}
  @media (max-width: 1700px) {
    padding-top: 70px;
  }
`

const CreditsContainer = styled.div`
  display: flex;
  height: 90%;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 0 7rem;
  ${isMobile &&
    `
    height: 100%;
    flex-direction: column;
    padding: 2rem;
  `}
  @media (max-width: 1700px) {
    padding: 0 6rem;
  }
  @media (max-width: 768px) {
    padding: 2rem;
  }
`

const CreditsSeparator = styled.div`
  position: relative;
  top: 8%;
  height: 65%;
  border: 1px solid rgba(38, 34, 34, 0.2);
  border-left: none;
  margin: 0 3rem;
  ${isMobile &&
    `
    display: none;
  `}
  @media (max-width: 1700px) {
    height: 60%;
  }
`

const CreditsColumn = styled.div`
  width: 100%;
  ${isMobile &&
    `
    width: 100%;
  `}
`

const CreditsCategory = styled.h2`
  font-family: "Red Hat Display", "Open Sans";
  color: #df3242;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  font-size: 0.8rem;
  @media (max-width: 1700px) {
    margin-bottom: 0.3rem;
    font-size: 0.7rem;
  }
`

const CreditsItem = styled.div`
  font-family: "Red Hat Display", "Open Sans";
  font-size: 0.9rem;
  margin-bottom: 1.2rem;
  line-height: 1.6;
  @media (max-width: 1700px) {
    margin-bottom: 1rem;
  }
`

const CreditsLogos = styled.div`
  display: flex;
  align-items: flex-start;
  ${isMobile &&
    `
      flex-direction: column;
      align-items: center;
      `}
`

const CreditsLogos2 = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1.5rem;
`

const CreditsLogo = styled.img`
  width: 7rem;
  margin-right: 2.625rem;
  ${isMobile &&
    `
        width: 80%;
      `}
`

const PartnersLogo = styled.img`
  margin-right: 2.625rem;
  ${isMobile &&
    `
        margin-right: 0;
        margin-bottom: 2rem;
      `}
`

const Credits = () => (
  <CreditsBody>
    <CreditsContainer>
      <CreditsColumn>
        <CreditsLogos2>
          <a href="https://www.polskieradio.pl/" target="_blank">
            <CreditsLogo src={pr2} alt="Polskie Radio" />
          </a>
          <a href="https://niepodlegla.gov.pl/" target="_blank">
            <CreditsLogo src={niepodlegla2} alt="Niepodlegla" />
          </a>
        </CreditsLogos2>
        <CreditsCategory tabIndex="0">
          Concept, scripting, research and aquisition of audiovisual materials
        </CreditsCategory>
        <CreditsItem tabIndex="0">
          <p>Izabella Mazurek, Bartłomiej Makowski, Krzysztof Kossowski</p>
          <p>With special thanks to prof. Grzegorz Nowik for substantial support</p>
        </CreditsItem>
        <CreditsCategory tabIndex="0">
          Concept and graphic design
        </CreditsCategory>
        <CreditsItem tabIndex="0">Łukasz Haponiuk, Paweł Woźniak</CreditsItem>
        <CreditsCategory tabIndex="0">Development team</CreditsCategory>
        <CreditsItem tabIndex="0">
          Piotr Gajcy, Łukasz Kowalski, Bartosz Miller, Michał Romańczuk,
          Mateusz Orłowski, Piotr Dul
        </CreditsItem>
        <CreditsCategory tabIndex="0">
          Project Coordination
        </CreditsCategory>
        <CreditsItem tabIndex="0">
          Grzegorz Kowalski, Piotr Gajcy, Małgorzata Dubel
        </CreditsItem>
        <CreditsCategory tabIndex="0">
          Testing and Technical Support
        </CreditsCategory>
        <CreditsItem tabIndex="0">
          Alan Krawczyk, Mariusz Łapiński, Paula Karolak
        </CreditsItem>
        <CreditsCategory tabIndex="0">Intro</CreditsCategory>
        <CreditsItem tabIndex="0">
          Bartosz Trojanowski (animation), Łukasz Haponiuk (post-production and
          sound), Jan Aleksandrowicz-Krasko (lector), Bartłomiej Makowski
          (screenplay)
        </CreditsItem>
        <CreditsCategory tabIndex="0">
          Audio description montage
        </CreditsCategory>
        <CreditsItem tabIndex="0">
          Dział Produkcji Multimedialnych Polskiego Radia
        </CreditsItem>
        <CreditsCategory tabIndex="0">Partners</CreditsCategory>
        <CreditsItem tabIndex="0">
          Paulina Łukawska, Anna Bińkowska, Marcin Rembacz
        </CreditsItem>
        <CreditsCategory tabIndex="0">Translation</CreditsCategory>
        <CreditsItem tabIndex="0">
          Instytut Polski w Budapeszcie, Barry Keane, Translation agency Lidex
        </CreditsItem>
      </CreditsColumn>
      <CreditsSeparator />
      <CreditsColumn>
        <CreditsCategory tabIndex="0">Photo materials sources</CreditsCategory>
        <CreditsItem tabIndex="0">
          Narodowe Archiwum Cyfrowe, Polska Agencja Prasowa, Biblioteka Narodowa
          (Polona.pl), Centralne Archiwum Wojskowe, East News – photo agency;
          Library of Congress; National Archives of Records Administration;
          Wikimedia Commons (public domain); Europeana.eu, National Library of
          Scotland; Zamek Królewski w Warszawie
        </CreditsItem>
        <CreditsCategory tabIndex="0">Audio materials sources</CreditsCategory>
        <CreditsItem tabIndex="0">
          Archiwum Polskiego Radia, Archiwum Rozgłośni Polskiej Radia Wolna
          Europa (RFE Polish Section Archive)
        </CreditsItem>
        <CreditsCategory tabIndex="0">Video materials sources</CreditsCategory>
        <CreditsItem tabIndex="0">
          <p>
            „Dla Ciebie Polsko”, director Antoni Bednarczyk, source: Filmoteka
            Narodowa – Instytut Audiowizualny;
          </p>
          <p>
            „Sztandar Wolności”, director Ryszard Ordyński, source: Filmoteka
            Narodowa – Instytut Audiowizualny;
          </p>
          <p>
            „Scenes of the Russian Revolution”; source: National Archives and
            Records Administration;
          </p>
          <p>
            „Partition of Poland”; source: National Archives and Records
            Administration;
          </p>
          <p>
            „Selected newsreel scenes on Russian satellite countries, Europe”;
            source: National Archives of Records Administration;
          </p>
          <p>„Weeknummer 22-08"; source: Openbeelden.nl /via Europeana.eu</p>
          <p>
            „Women Troops For Polish / Russian War Aka Poland’s Army Of Amazons
            1920"; source: British Pathé
          </p>
        </CreditsItem>
        <CreditsCategory tabIndex="0">Cooperation</CreditsCategory>
        <CreditsItem tabIndex="0">
          <p>
            Filmoteka Narodowa – Instytut Audiowizualny, Muzeum Józefa
            Piłsudskiego w Sulejówku
          </p>
          <p>
            This project was organised as part of the celebrations of the
            centenary of regaining independence and rebuilding the Polish
            statehood.
          </p>
        </CreditsItem>
        <CreditsLogos>
          <PartnersLogo
            src={niepodlegla}
            style={{ width: "6rem" }}
            alt="Ministerstwo Kultury i Dziedzictwa Narodowego"
          />
          <PartnersLogo
            src={pr}
            style={{ width: "7rem" }}
            alt="Polskie Radio"
          />
          <PartnersLogo
            src={pilsudski}
            style={{ width: "8rem" }}
            alt="Muzeum Jozefa Pilsudskiego w Sulejowku"
          />
          <PartnersLogo src={fina} style={{ width: "4rem" }} alt="FINA" />
          <PartnersLogo src={bud} style={{ width: "6rem" }} alt="IPB" />
        </CreditsLogos>
      </CreditsColumn>
    </CreditsContainer>
  </CreditsBody>
)

export default Credits
